.client-pic{
    width: 80px;
    height:80px;
    border:1px solid white;
    border-radius: 50%;
    
}
body{
  overflow-x: hidden !important;
}

/* Custom CSS for Sticky Navigation Bar */
.sticky-navbar {
    position: sticky;
    top: 0;
    background-color: #333; /* Change the background color */
    z-index: 1000; /* Set the z-index to control stacking order */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a shadow for emphasis */
}

/* Style for the links in the navigation bar */
.sticky-navbar .nav-link {
    color: #fff; /* Change link color */
    padding: 8px 20px;
    text-decoration: none;
    font-weight: bold;
}

.sticky-navbar .nav-link:hover {
    background-color: #555; /* Change the link's background color on hover */
}


.success-message {
    background-color: #4CAF50; /* Green background color */
    color: white; /* White text color */
    padding: 10px; /* Padding around the message */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 10px; /* Add some space below the message */
    text-align: center; /* Center the text */
    font-size: 16px; /* Adjust the font size as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a shadow for emphasis */
  }
  
  /* Add a transition for smooth appearance and disappearance */
  .success-message {
    transition: opacity 0.5s, transform 0.5s;
  }
  
  /* Define the style when it's hidden */
  .success-message.hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none; /* Prevent interactions with hidden message */
  }
  .slider-1  {
    bottom: 8.25rem;
  }


  /* .whatsapp-icon {
    position: fixed;
    bottom: 60px;
    right: 60px;
    box-shadow: 0 11px 11px 14px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  
  .whatsapp-icon a {
    display: block;
    width: 90px;
    height: 90px;
    background-color: green; 
    border-radius: 50%;
    text-align: center;
    line-height: 60px;   
  }
  
  .whatsapp-icon img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  } */


  .whatsapp-button {
    position: fixed;
    bottom: 30px; /* Adjust this value to change the vertical position */
    right: 30px; /* Adjust this value to change the horizontal position */
    background-color: #4bbb74; /* WhatsApp green color */
    border: none;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 8px 5px rgba(0, 0, 0, 0.2);
    z-index: 999; /* Ensure it's on top of other elements */
  }
  
  .whatsapp-button img {
    width: 50px; /* Adjust the size of the WhatsApp icon as needed */
    height: 50px;
    border-radius: 50%;
  }


  .navbar-toggler-icon{
    background-color: #fff;
    width: 2.53rem;
    height:2.53rem
    ;
  }
  .navbar-toggle{
    background-color: white !important;
  }

  @media (max-width:600px) {
    .mob-res{
         padding-left: 0 !important;
         padding-right: 0 !important;
    }
    .mob-dis{
       flex-direction: column;
       align-items: center;
       justify-content: center;
    }
    .logo-mob{
        display: flex;
        flex-direction: column;

    }
    .slider-1{
      bottom: 2.23rem;
    }
    .res-hide{
       display: none;
    }
    .img-res{
      height:250px
    }
    
  }
  @media (min-width:992px) and (max-width:1201px) {

    .mob-res{
      padding-left: 0 !important;
      padding-right: 0 !important;
 }

  }

  /* .th-btn {
    position: relative;
  z-index: 2;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: green;
  color: white;
  font-family: var(--body-font);
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 20.5px 29px;
  border-radius: 0;

} */